body {
  font-family: sans-serif;
  background: '#dfdfdf';
  margin: 2% !important;
  width: 100% !important;
  height: 100% !important;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: '#dfdfdf';
  text-align: center;
}
.disclaimer {
  color: #5a646e;
  font-size: 14px;
  line-height: 21px;
  margin-top: 10%;
  position: relative !important;
}

.timer {
  font-size: 12px;
  padding-left: 16px;
  padding-right: 16px;
  color: #5a646e;
  text-align: right;
}

.resend {
  text-align: center !important;
  margin-top: 10% !important;
}

.cancel {
  text-align: center !important;
  margin-top: 10% !important;
}

.email {
  text-align: center !important;
  margin-top: 5% !important;
}

.success {
  text-align: center !important;
  margin-top: 10% !important;
}

.resend-button {
  text-align: center !important;
  text-decoration-line: underline !important;
  font-size: 12px !important;
  color: #2e8cf6 !important;
}

.cancel-button {
  text-align: center !important;
  text-decoration-line: underline !important;
  font-size: 12px !important;
  color: #dd2a37 !important;
}

.success-button {
  text-align: center !important;
  text-decoration-line: underline !important;
  font-size: 12px !important;
  color: #2e8cf6 !important;
}

.g-recaptcha > div {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  width: 100% !important;
}

.g-recaptcha > iframe {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  width: 100% !important;
}

.g-recaptcha iframe {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  width: 100% !important;
}

.notification {
  color: #5a646e;
  font-size: 12px;
  text-align: left;
  margin-top: 40px;
}

.timer-box {
  width: 100% !important;
}

.notification-error-code {
  padding-left: 16px !important;
  font-size: 14px !important;
  text-align: left !important;
  padding-bottom: 2vh !important;
  color: #dd2a37;
}

.title-text {
  padding-top: 15%;
  font-size: 24px;
  color: #1e5bc6;
}

.ubuntu {
  font-style: normal;
  font-weight: 400;
}

.styles_title__1cca0 {
  padding-left: 16px !important;
  color: #5a646e !important;
  font-size: 14px !important;
  text-align: left !important;
  padding-bottom: 2vh !important;
}

.styles_react-code-input-container__tpiKG {
  width: 100% !important;
  position: relative !important;
  padding-top: 10% !important;
}

.styles_react-code-input__CRulA > input {
  border: solid 1px#C5CED7;
  border-right: solid 1px#C5CED7 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  font-family: 'sans-serif' !important;
  font-size: 30px !important;
  color: #5a646e !important;
  margin: 1% !important;
  width: 14% !important;
  height: 8vh !important;
}

.styles_react-code-input__CRulA > input:focus {
  outline: none !important;
  border: 1px solid#2E8CF6 !important;
  caret-color: #2e8cf6 !important;
  border-right: solid 1px#2E8CF6 !important;
}

.invalid-react-code-input > div > input {
  border: 1px solid #dd2a37 !important;
  caret-color: #dd2a37 !important;
  border-right: solid 1px #dd2a37 !important;
}

.success-react-code-input > div > input {
  border: 1px solid #0fc758 !important;
  caret-color: #0fc758 !important;
  border-right: solid 1px #0fc758 !important;
}

.styles_react-code-input__CRulA > input:focus + input {
  border-left: solid 1px#C5CED7 !important;
}
